import React from "react";
import LayoutMain from "../../layouts/LayoutMain";
import Seo from "../../components/seo";
import Feature from "../../components/ui/Feature.js";
import { Row, Col, Divider, Typography } from "antd";
import { Link } from "gatsby";
import LearnMore from "../../components/sections/LearnMore.js";

const { Title, Paragraph } = Typography;

const FeaturesLayoutPage = () => (
  <LayoutMain>
    <Seo
      title="Features | Layout"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      className="heading"
      style={{ backgroundColor: "#fff" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>Layout & styling</Title>
        <Title level={2} style={{ color: "white" }}>
          inFlow Features
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
              className="typography-nice alt"
            >
              User-friendly &amp; beautiful
            </Title>
          </Col>
          <Feature
            title="Layouts"
            description="Use our catalog of layouts to create the perfect chart for your use-case."
          />
          <Feature
            title="Fully responsive"
            description="Through tailored interactions all our tools work great on smaller devices."
          />
          <Feature
            title="Data driven"
            description="Drive multiple types of charts from a single source of json data."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
              className="typography-nice alt"
            >
              Fully customizable
            </Title>
          </Col>
          <Feature
            title="Theming"
            description="Use our catalog of layouts to create the perfect chart for your use-case."
          />
          <Feature
            title="Branding"
            description="Through tailored interactions all our tools work great on smaller devices."
          />
          <Feature
            title=""
            description="Drive multiple types of charts from a single source of json data."
          />
          <Col>
            <Paragraph>
              View our{" "}
              <Link to="/examples/premium/example-medical-assessment">
                Examples
              </Link>
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <LearnMore />
  </LayoutMain>
);

export default FeaturesLayoutPage;
